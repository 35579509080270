.notfound img { 
    float: right;
}

.notfound {
    align-items: center;
    height: 80vh;
    overflow: hidden;
    margin-bottom: 8rem;
}

.notfound h1 {
    font-size: 2.5rem;
    font-weight: 900;
    color: #3F3F3F;
    margin-bottom: 1.25rem;
}

.notfound h2 {
    font-size: 1.25rem;
    font-weight: 400;
    color: #3F3F3F;
    max-width: 370px;
    margin-bottom: 1.75rem;
}

.notfound a {
    font-size: 1.25rem;
    font-weight: 500;
}

@media (max-width: 800px) {
    .notfound img {
        float: none;
    }
}
