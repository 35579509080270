/* Navbar */
.logo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.navbar-title {
  font-weight: 450;
}

.candidates {
  background-color: rgb(69, 115, 243);
  box-shadow: 0 1px 3px 0 rgb(202, 206, 209);
  color: white;
  border-radius: 15px;
  padding: 6px 19px 6px 19px;
  font-weight: 500;
  top: 15px;
  right: 50px;
  position: absolute;
  z-index: 1;
  font-size: 17px;
}

.phone-explain-text {
  font-size: 16px;
  opacity: 0.8;
}

.blog-navbar {
  margin-right: 30px;
}
/* Top */
.top {
  margin: 50px 0 30px 100px;
}

.top-img {
  width: 500px;
  opacity: 0.9;
  margin: 70px 60px -10px 60px;
  border-radius: 20px;
}

.play-video {
  font-size: 85px;
  position: absolute;
  margin-left: 280px;
  margin-top: 205px;
  z-index: 1;
}
.play-video:hover {
  transform: scale(1.03);
  cursor: pointer;
}

.home-video {
  width: 450px;
  height: 300px;
  border: none;
  border-radius: 15px;
  margin: 15px 0 15px 8px;
}

.top-title {
  margin-top: 100px;
}
.hire-best {
  font-size: 55px;
  font-weight: 900;
}
.whatever-need {
  font-weight: bolder;
  font-size: 48px;
  font-weight: 800;
}

.checklist {
  margin-top: 35px;
  margin-left: -10px;
  font-weight: 650;
  padding-right: 150px;
  font-size: 20px;
}

.cta-button {
  padding: 15px 25px 15px 25px;
  margin-top: 10px;
  margin-left: 10px;
  background-color: rgb(67, 117, 224);
  color: white;
}

.staff-explain-text {
  margin-top: 20px;
  opacity: 0.8;
  font-size: 17px;
  margin-left: 60px;
}

/* Trusted */
.trusted {
  background-color: rgb(250, 250, 250);
  padding-top: 30px;
  padding-bottom: 60px;
  margin: 100px 0 0 0;
  padding-right: 20px;
  padding-left: 20px;
  text-align: center;
}

.trusted-title {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 50px;
}

.trusted-img {
  width: 150px;
  opacity: 0.75;
}

/* How */
.how {
  margin: 50px;
}

.how-title {
  text-align: center;
  font-size: 55px;
  font-weight: 700;
  margin-bottom: 40px;
}

.how-explain {
  text-align: center;
  font-size: 18px;
}

.how-img-div {
  text-align: center;
  margin-bottom: 60px;
}
.how-img {
  width: 370px;
}

.number {
  background-color: rgb(31, 31, 31);
  color: white;
  border-radius: 100%;
  width: 40px;
  padding: 2px 0 2px 0;
  text-align: center;
  font-size: 25px;
  font-weight: 800;
  margin-bottom: 25px;
}

.pointers {
  margin-left: 100px;
  margin-top: 50px;
}

.how-point {
  margin-right: 180px;
  margin-bottom: 70px;
}

.how-point-title {
  font-size: 28px;
  font-weight: 600;
}

.how-point-explain {
  font-size: 18px;
}

/* Monitoring */
.benefits {
  margin: 100px 50px 40px 150px;
}

.benefits-title {
  font-size: 50px;
  font-weight: 700;
}
.benefits-explain {
  opacity: 0.8;
  font-size: 16px;
  margin-left: 7px;
  margin-bottom: 50px;
  margin-right: 10%;
}

.benefit {
  margin-bottom: 100px;
}
.benefit-title {
  font-size: 35px;
  font-weight: 650;
  margin-top: 50px;
}
.benefit-img {
  width: 200px;
  height: 200px;
  margin-left: 10px;
  opacity: 0.9;
  border-radius: 10px;
  box-shadow: 2px 1px 3px 0 rgb(211, 211, 211);
}
.benefit-explain {
  opacity: 0.7;
  font-size: 16px;
}

/* Reviews */
.video-title {
  font-size: 45px;
  font-weight: 700;
  margin-top: 100px;
}

.videos {
  text-align: center;
  margin: 70px 10px 50px 10px;
}

.video-review {
  margin-bottom: 50px;
}

.review-name {
  font-size: 27px;
  font-weight: 600;
}

.review-firm {
  font-size: 20px;
  opacity: 0.8;
  color: rgb(72, 72, 241);
}

.review-video {
  width: 90%;
  border: none;
  border-radius: 15px;
  margin: 15px 0 15px 8px;
}

.reviews {
  margin: 200px 180px 150px 220px;
}

.five-star {
  font-size: 30px;
  font-weight: 600;
}

.star {
  margin-right: 10px;
  color: rgb(243, 217, 70);
  font-size: 28px;
  margin-bottom: 50px;
}
.star:hover {
  transform: scale(1.1);
}

.review {
  font-size: 15px;
  font-weight: 450;
  margin: 0 100px 0 100px;
}

.carousel-control-next-icon {
  background-color: rgb(0, 0, 0);
  border-radius: 10px;
  width: 15px;
}
.carousel-control-prev-icon {
  background-color: black;
  border-radius: 10px;
  width: 15px;
}

.trusted-firms-div {
  text-align: center;
  margin-top: 100px;
  margin-bottom: 100px;
}

.scroll-img {
  width: 150px;
  padding: 10px;
  margin: 0 50px 0 50px;
  opacity: 0.9;
}

/* Footer */
.footer {
  background-color: rgb(248, 251, 252);
  box-shadow: 2px 1px 3px 0 rgb(151, 151, 151);
  border-radius: 15px;
  padding: 20px 35px 40px 35px;
}

.footer-content {
  margin: 50px 50px 0 50px;
}

.footer-pic {
  width: 85px;
}
.footer-title {
  margin-bottom: 30px;
  font-size: 22px;
  font-weight: 700;
}

.footer-point {
  font-size: 19px;
  font-weight: 700;
}

.terms {
  margin: 80px;
}

.success {
  text-align: center;
  font-size: 30px;
  margin-top: 100px;
  font-weight: 600;
}

.success-img {
  width: 300px;
}

.sub-input {
  border: 0;
  margin-bottom: 30px;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgb(206, 211, 214);
  background-color: rgb(252, 253, 253);
  width: 50%;
}

.sub-button {
  margin-left: 30px;
  font-size: 16px;
}

.sub-text {
  margin-bottom: 35px;
}

/* Benefits */
.benefits {
  margin: 50px;
}
/* benefit card */
.benefit-card {
  box-shadow: 0px 13px 54px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 20px;
  margin: 15px 0;
  background-color: white;
}
.benefit-card--title {
  line-height: 1.25;
  margin-bottom: 0;
  min-height: 40px;
}
.benefit-card--icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #e3f2ff;
  padding: 14px;
}
.benefit-card--icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.managing {
  padding: 80px 0;
}
/* info-section */
.info-section {
  background-color: #e7e7e7;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 80px 0;
}
.info-section--content {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 50px;
}
.info-section--content figure {
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 2;
}
.info-section--content figure:before {
  content: "";
  width: 45%;
  height: 70px;
  display: block;
  border-radius: 10px;
  position: absolute;
  left: -20px;
  top: -20px;
  z-index: -1;
  background-color: #1c1c1e;
}
.info-section--content figure img {
  width: 500px;
  height: 300px;
  border-radius: 10px;
  object-fit: cover;
}
.info-section--card {
  position: relative;
  z-index: 1;
  transform: translate(-36px, 30px);
}
.info-section--card-content {
  background: #ffffff;
  box-shadow: 0px 4px 214px rgba(0, 0, 0, 0.02);
  border-radius: 20px;
  padding: 60px 40px 60px 80px;
  font-size: 1.125em;
  font-weight: 500;
  line-height: 1.5;
}
.info-section--card::before {
  content: "";
  width: 30%;
  height: 70px;
  display: block;
  border-radius: 10px;
  position: absolute;
  left: -20px;
  bottom: -20px;
  z-index: -1;
  background-color: #4475e0;
}
/* Smaller Screens */
@media (max-width: 1000px) {
  .top-title {
    margin: 30px 0 80px 0;
  }

  .top {
    margin: 50px;
  }

  .top-img {
    width: 350px;
    margin-top: 5px;
  }

  .cta-button {
    margin-top: 95px;
  }

  .play-video {
    font-size: 85px;
    position: absolute;
    margin-left: 200px;
    margin-top: 120px;
    z-index: 1;
  }

  .how-point {
    margin-right: 5px;
  }

  .reviews {
    margin: 0 0 100px 0;
    text-align: center;
  }

  .review {
    margin: 0 60px 0 60px;
  }

  .footer {
    padding: 5px;
  }

  .trusted-firms {
    width: 70%;
  }

  .trusted-img {
    width: 100px;
  }

  .info-section--content figure img {
    width: 260px;
    height: 200px;
  }
}

@media (max-width: 767px) {
  .info-section--content {
    flex-direction: column;
    align-items: center;
  }
  .info-section--card{
    transform: none;
  }
}
/* Phone */
@media (max-width: 500px) {
  .reviews {
    margin: 40px 10px 100px 25px;
    text-align: center;
  }

  .review {
    margin: 0 60px 0 60px;
  }

  .hire-best {
    font-size: 45px;
  }
  .whatever-need {
    font-size: 40px;
  }

  .top {
    margin: 50px;
  }

  .top-img {
    display: none;
  }

  .checklist {
    font-size: 15px;
    padding: 0;
  }

  .play-video {
    display: none;
  }

  .cta-button {
    margin-top: 30px;
  }

  .candidates {
    padding: 5px;
    font-size: 12px;
    right: 7px;
    margin-top: 2px;
  }

  .trusted-title {
    font-size: 35px;
  }

  .trusted-img {
    width: 75px;
  }

  .checklist {
    display: none;
  }

  .staff-explain-text {
    display: none;
  }

  .pointers {
    margin-left: 10px;
    margin-top: 50px;
  }

  .how-point {
    margin-right: 20px;
    margin-bottom: 70px;
  }

  .how-point-title {
    font-size: 28px;
    font-weight: 600;
  }

  .how-point-explain {
    font-size: 18px;
  }

  .benefits {
    margin-left: 40px;
  }

  .reviews {
    margin: 0 0 100px 0;
  }

  .navbar-title {
    display: none;
  }

  .scroll-img {
    width: 70px;
    padding: 5px;
  }
}
