.a-va-profile {
  background-color: rgb(253, 253, 254);
  border-radius: 27px;
  padding: 40px;
  box-shadow: 0 1px 3px 0 rgb(202, 206, 209);
  margin: 50px 100px 50px 100px;
}

.profile-bio {
  font-size: 15px;
  margin-top: 20px;
  opacity: 0.8;
}

.profile-va-video {
  margin-top: 30px;
  border: 0;
  border-radius: 10px;
  margin-bottom: 35px;
  width: 400px;
  height: 250px;
}

.virtual-agent-profile {
text-align: center;  margin-top: 30px;
  font-size: 35px;
  font-weight: 600;
}

.request-profile-va {
  background-color: rgb(69, 115, 243);
  width: 50%;
  margin-top: 70px;
  padding: 10px;
}
.certificate-profile {
  opacity: 0.8;
  font-size: 15px;
  margin-top: 5px;
  margin-left: 10px;
}

/* Smaller screens */
@media (max-width: 1100px) {
  .a-va-profile {
    margin: 50px 30px 50px 30px;
    padding: 20px;
  }
}

/* Phone */
@media (max-width: 700px) {
  .a-va-profile {
    margin: 50px 20px 50px 20px;
    padding: 15px;
  }

  .profile-va-video {
    margin-top: 30px;
    border: 0;
    border-radius: 10px;
    margin-bottom: 35px;
    width: 300px;
    height: 210px;
  }

  .request-profile-va {
    width: 70%;
    margin-top: 20px;
  }

  .virtual-agent-profile {
    font-size: 23px;
    margin: 20px 20px -10px 20px;
  }
 
}
