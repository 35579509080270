/* Auth */
.auth {
  margin: 100px 30% 100px 30%;
  padding: 50px;
  text-align: center;
  box-shadow: 0 1px 3px 0 rgb(206, 211, 214);
}

.auth-title {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 35px;
}

.auth-input {
  border: 0;
  margin-bottom: 30px;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgb(206, 211, 214);
  background-color: rgb(252, 253, 253);
  width: 90%;
}
::placeholder {
  font-size: 16px;
  font-weight: 600;
}

.auth-button {
  border-radius: 14px;
  width: 90%;
  margin: 10px 0 30px 0;
  background-color: #242424;
  box-shadow: 0 1px 3px 0 rgb(206, 211, 214);
  font-weight: 800;
  color: white;
  padding: 10px;
}

/* Admin */

.admin {
  margin: 50px;
}

.admin-add {
  background-color: #6484ec;
  box-shadow: 0 1px 3px 0 rgb(202, 206, 209);
  color: white;
  border-radius: 10px;
  padding: 9px 19px 9px 19px;
  font-weight: 500;
  top: 12px;
  right: 30px;
  position: absolute;
  z-index: 1;
}

.va-bio {
  height: 100px;
}

.add-form {
  height: 690px;
  overflow-y: scroll;
}

/* General */
.all-labels {
  color: gray;
  font-size: 12px;
  margin-bottom: 15px;
  margin-top: 45px;
}

.admin-nav-image {
  width: 65px;
  margin-top: -6px;
}

.admin-student-pick {
  text-align: center;
  margin: 15px 390px 25px 390px;
}

.admin-pick {
  padding: 10px;
  background-color: rgb(223, 223, 223);
  color: black;
}

.admin-picked {
  padding: 10px;
  background-color: black;
  color: white;
}

.a-data {
  margin-top: 30px;
  border: 1px lightgray solid;
  border-radius: 15px;
  padding: 15px;
}
.a-data:hover {
  border: 1px #5a8ce7 solid;
  cursor: pointer;
}

.attribute {
  font-weight: 600;
}
.admin-image {
  margin-top: 30px;
  width: 85px;
}

.admin-dropdown:hover {
  cursor: pointer;
  color: rgb(146, 145, 145);
}

.inactive {
  background-color: #d35853;
  font-size: 13px;
  margin-left: 30px;
  margin-bottom: 10px;
  padding: 5px 10px 5px 10px;
}

.available {
  background-color: #64c264;
  font-size: 13px;
  margin-left: 30px;
  margin-bottom: 10px;
  padding: 5px 10px 5px 10px;
}

.vid-text {
  font-size: 15px;
}
/* Containers */
.admin-container {
  padding: 20px;
}

.all-students-container {
  margin-top: 45px;
}

/* Data */

.data-title {
  margin-left: 5px;
  margin-bottom: 15px;
}

.when-data {
  font-size: 14px;
  margin-left: 5px;
  opacity: 0.8;
}
.when-data:hover {
  color: black;
  cursor: pointer;
}

.when-data-pick {
  opacity: 1;
  color: black;
}

.va-img {
  border-radius: 100%;
  width: 70px;
  height: 70px;
}

/* Profile Pics */
.profile-pic {
  margin-bottom: 30px;
}

#upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.upload {
  padding: 10px;
  margin: 10px 0 10px 25px;
  border-radius: 15px;
  background-color: #fcfcfc;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
}
.upload:hover {
  cursor: pointer;
}

.settings-box {
  text-align: left;
  margin-left: 50px;
  margin-top: 30px;
}

.settings-save {
  margin-bottom: 25px;
  margin-top: 10px;
  padding: 14px;
  border-radius: 17px;
  background-color: #09ac5d;
}

.settings-image {
  border-radius: 100%;
  width: 75px;
}

.upload-photo {
  margin-left: 20px;
  margin-top: 40px;
}

/* Search */

input {
  box-sizing: border-box;
  font-size: 13px;
  vertical-align: top;
}

.search-div {
  margin-top: 100px;
  margin-bottom: 100px;
}
.search-div-big {
  margin-bottom: 65px;
  margin-top: -55px;
}
.search-bar{
  padding: 0 30px;
  font-size: 18px;
  width: 75%;
  max-width: 500px;
  height: 80px;
  box-shadow: 0 1px 3px 0 rgb(202, 206, 209);
  border: 0;
  border-radius: 30px;
  border: 0.1px rgb(206, 206, 206) solid;
  margin-left: 20px;
  outline: none;
}

.submit {
  cursor: pointer;
  border: none;
  box-shadow: 0 1px 3px 0 rgb(202, 206, 209);
  background-color: white;
  background-size: 35px 35px;
  border-radius: 100px;
  padding: 15px 18px;
  font-size: 14px;
  display: inline-block;
  margin-left: -65px;
  color: black;
  margin-top: 12px;
}

.search-big {
  padding: 0 30px;
  font-size: 18px;
  width: 70%;
  height: 80px;
  box-shadow: 0 1px 3px 0 rgb(202, 206, 209);
  border-radius: 25px;
  border: 0.1px rgb(206, 206, 206) solid;
  margin-left: 70px;
  outline: none;
}

.search-container-big {
  padding-top: 90px;
  padding-bottom: 0;
  margin-bottom: 0;
}

/* Phone Responsiveness */

@media (max-width: 800px) {
}
