.subpage-cover {
  padding: 100px 0;
  text-align: center;
  max-width: 900px;
  margin: auto;
}
.subpage-cover h2 {
  font-size: 55px;
  font-weight: 700;
}
.subpage-cover p {
  margin-top: 30px;
  color: #4f4f4f;
  line-height: 1.75;
  font-size: 1.225em;
  font-weight: 400;
}
.training,
.staff {
  padding: 80px 0;
}
.text-color {
  color: #4f4f4f;
}

.training-benefits-list {
  font-size: 1.125em;
  line-height: 2;
  margin-top: 30px;
}
