.blog-mobile-post-img {
    display: none;
}

.blog-home {
    margin: 30px;
    padding: 30px;
    justify-content: center;
}

.share-div i {
    font-size: 30px;
    margin-top: 22px;
    align-self: center;
    margin-left: 20px;
    text-align: center;
    line-height: 30px;
}

.card-bottoms {
    position: absolute;
    bottom: 30px;
}

.share-col i{
    margin-left: 20px;
    font-size: 30px;
    color: black;
}

.share-col {
    align-self: center;
}

.share-col * {
    margin-top: 3px;
}

.blog-card-image {
    height: 250px;
    object-fit: contain;
}

.share-div {
    display: flex;
    margin-top: 10px;
}

.share-div * {
    margin: 5px;
}

.share-div h6{
    font-size: 20px;
    line-height: 30px;
    color: black;
    margin-top: 10px;
}


.h1-SEO {
    text-align: center;
    margin-top: 60px;
    font-weight: 400;
    font-size: 32px;
    color: rgb(100, 100, 100);
}

.blog-cards {
    width: 18rem;
    margin: 15px;
    border-radius: 15px;
    height: 625px;

    /* box-shadow: 1px -1px 2px 0px rgba(204,204,204,0.75);
    -webkit-box-shadow: 1px -1px 2px 0px rgba(204,204,204,0.75);
    -moz-box-shadow: 1px -1px 2px 0px rgba(204,204,204,0.75); */
}

.blog-home .candidates{
    margin-left: 0px !important;
}

.blog-home h2 {
    height: 115px;
    overflow: hidden;
    color: 000;
    font-size: 32px;
    font-weight: 700;
}

.blog-home h5 {
    font-weight: 300;
    font-size: 16px;
    color: rgb(70, 70, 70);
}

.blog-post-wrapper {
   margin: 60px;
}

.head-data {
    margin-left: 240px;
    margin-right: 240px;
    margin-bottom: 60px;
}

.head-data .head-text {
    align-self: center;
}

.head-data img {
    max-width: 250px;
    max-height: 250px;
}

.blog-post-wrapper .content {
    margin-left: 240px;
    margin-right: 240px;
}

.blog-post-wrapper .content h2 {
    font-size: 28px;
    font-weight: 500;
}

.blog-post-wrapper h1 {
    font-weight: 600;
    font-size: 32px;
}

.blog-post-wrapper h2 {
    font-weight: 400;
    font-size: 24px;
}

.blog-post-wrapper p {
    font-weight: 400;
    font-size: 18px;
}

.blog-post-wrapper .tags {
    margin-top: 60px;
    font-weight: 400;
    font-size: 20px;
    color: #ccc;
}


.card-img-top {
    max-height: 250px;
}

.card-content {
    max-height: 76px;
    overflow: hidden;
}

@media (max-width: 1043px) {
    .head-data {
        margin-left: 0px;
        margin-right: 0px;
    }

    .blog-post-wrapper .content {
        margin-left: 0px;
        margin-right: 0px;
    }
}

@media (max-width:500px) {
    .blog-mobile-post-img {
        display: block;
    }

    .blog-desktop-post-img {
        display: none;
    }

    .head-data img{
        max-width: 100%;
    }

    .blog-post-wrapper {
        margin: 30px;
    }

    .head-text {
        padding-left: 0px !important;
        padding-right: 0px !important;
        /* margin-bottom: -30px; */
    }

    .head-data img {
        margin-bottom: 60px;
        text-align: center;
    }

    .blog-home {
        margin-left: 0px;
        margin-right: 0px;
    }

    .blog-cards .candidates {
        padding: 6px 19px 6px 19px !important;
        /* width: 80px; */
        height: 40px;
    }

    .head-text {
        margin-left: 0px;
        margin-right: 0px;
    }

    .blog-cards {
        width: 25rem;
    }

    .share-col i{
        margin-top: 30px;
    }

    .share-col {
        margin-left: -15px;
        margin-bottom: 30px;
    }

    .share-col * {
        margin-right: 10px;
    }
}

@media(max-width: 374px) {
    .blog-post-wrapper {
        margin: 15px;
    }
}