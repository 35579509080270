/* Search page */
.search {
  margin-top: 70px;
  text-align: center;
}

.candidate-filter {
  margin: 0 100px 0 100px;
}

.candidate-pick {
  padding: 7px;
  background-color: rgb(223, 223, 223);
  color: black;
}
.candidate-picked {
  padding: 7px;
  background-color: black;
  color: white;
}

.a-va {
  margin: 70px;
  background-color: white;
  border-radius: 27px;
  box-shadow: 0 1px 3px 0 rgb(202, 206, 209);
  text-align: left;
  padding: 30px;
}

.candidate-name {
  font-size: 20px;
  font-weight: 600;
}

.candidate-specialty {
  font-size: 18px;
  font-weight: 500;
}

.candidate-languages {
  font-size: 17px;
  opacity: 0.9;
}

.va-statement {
  padding-top: 15px;
  font-size: 14px;
  opacity: 0.8;
}

.va-statement-div {
  margin-top: 50px;
}

.request-va {
  margin-top: 100px;
  margin-left: 70px;
  font-size: 16px;
  padding: 12px 35px 12px 35px;
  background-color: #1e1e20;
}
.added-va {
  margin-top: 110px;
  margin-left: 110px;
  font-size: 20px;
  font-weight: 700;
}

.list {
  padding: 25px;
  text-align: center;
  background-color: #546afa;
  color: white;
  right: 60px;
  border-radius: 100%;
  width: 100px;
  top: 120px;
  position: fixed;
}
.list:hover {
  transform: scale(1.02);
  cursor: pointer;
}

.requested {
  margin-top: 100px;
  margin-left: 70px;
  font-size: 22px;
  font-weight: 600;
}

.explain-request-text {
  font-size: 15px;
}

.see-more {
  text-align: center;
}

.va-online {
  color: #32cd32;
  font-size: 13px;
}

.play-va-video {
  margin-left: -84px;
  font-size: 35px;
  color: white;
}
.play-va-video:hover {
  color: #fd5e53;
  transform: scale(1.1);
  transition-duration: 0.2s;
}

.certificate {
  margin-left: 125px;
  margin-top: 5px;
  font-size: 15px;
  font-weight: 700;
}
.check {
  color: #0fa75d;
  margin-right: 5px;
}

.specialties {
  padding-bottom: 40px;
  margin-top: 25px;
}

.subject {
  padding: 7px 12px 7px 12px;
  margin: 10px 15px 0 0;
  font-size: 14px;
  border-radius: 12px;
  font-weight: 500;
  box-shadow: 0 1px 3px 0 rgb(184, 184, 184);
  background-color: #ccd2f7;
  color: black;
}
.subject:hover {
  transform: none;
  cursor: default;
}

@media (max-width: 800px) {
  .certificate {
    display: none;
  }

  .va-statement-div {
    margin-top: 20px;
  }

  .request-va {
    margin-left: -5px;
    margin-top: 50px;
    margin-bottom: 15px;
  }
}

/* Phone Responsiveness */

@media (max-width: 600px) {
  .profile-container {
    padding: 10px 25px 10px 25px;
    margin: 20px 15px 70px 15px;
  }

  .candidate-filter {
    display: none;
  }
}

/* VA Video */

.va-image {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  margin-bottom: 20px;
  z-index: 1;
}

.modal-video {
  margin-left: 20px;
  border-radius: 15px;
  margin-bottom: 20px;
  z-index: 1;
  border: 0;
}

.profile-video {
  margin-top: 30px;
  border: 0;
  border-radius: 10px;
  margin-bottom: 35px;
}

.va-video-play {
  position: absolute;
  margin-left: 60px;
  margin-top: -122px;
  font-size: 45px;
  color: rgb(252, 252, 252);
}
.va-video-play:hover {
  transform: scale(1.03);
  cursor: pointer;
}

@media (max-width: 470px) {
  .a-va {
    margin: 20px;
    padding: 15px;
    margin-bottom: 45px;
  }

  .modal-video {
    width: 280px;
    height: 143px;
  }
}
