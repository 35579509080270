body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(36, 36, 36);
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  border: 0;
  background-color: rgb(14, 14, 14);
  padding: 7px 15px 7px 15px;
  border-radius: 12px;
  color: white;
  font-weight: 600;
}

button:focus {
  outline: 0;
}

button:hover {
  transform: scale(1.01);
}

.inactive-button {
  opacity: 0.6;
}
.inactive-button:hover {
  transform: none;
  cursor: not-allowed;
}

.center-it {
  text-align: center;
}

.dont-appear {
  display: none;
}

.general-container {
  background-color: rgb(253, 253, 254);
  border-radius: 27px;
  padding: 15px;
  box-shadow: 0 1px 3px 0 rgb(202, 206, 209);
}

.explain-text {
  opacity: 0.65;
  font-size: 12px;
}

.nothing-found {
  margin-top: 10%;
  text-align: center;
}

/* Colors */
.green {
  color: rgb(59, 160, 59);
}
.red {
  color: rgb(224, 70, 70);
}
.light-blue {
  color: rgb(142, 142, 243);
}
.blue {
  color: rgb(69, 115, 243);
}
.brown {
  color: rgb(158, 80, 80);
}
.yellow {
  color: yellow;
}
.light-bg {
  background-color: #fafafa;
}
.mw900 {
  max-width: 900px;
}
.heading-title {
  text-align: center;
  font-size: 55px;
  font-weight: 700;
  max-width: 60rem;
  margin: auto;
  margin-bottom: 40px;
}
.heading-title.sm {
  font-size: 45px;
}
.heading-title.xs {
  font-size: 25px;
}
/* Phone */
